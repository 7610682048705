/* App.css */
.main-app {
    text-align: center;
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .cta-button {
    padding: 10px 20px;
    margin-bottom: 5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
  }
  
  .connect-wallet-button {
    background-color: #3498db;
  }
  
  .connected-wallet-button {
    background-color: #27ae60;
  }
  
  .save-contract-addr-button {
    background-color: #f39c12;
  }
  
  .mint-nft-button {
    background-color: #9b59b6;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  textarea {
    width: 100%;
    padding: 5px;
    height: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    resize: vertical;
  }

  select {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
 /* App.css */
.account-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .account-info-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .account-info-item-label {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .account-info-item-value {
    color: #666;
  }  

  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  
